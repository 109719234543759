@import "../../scss/settings";

.StepLayout {
  font-family: $font-body;
  font-size: 16px;
  color: $color-primary;
  background: white;
  padding-top: 55px;
  padding-bottom: 55px;
  height: 100vh;
  display: grid;
  width: 100vw;

  @media only screen and (max-width: 600px) {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 87vh;
  }

  &__title {
    font-family: $font-body;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 18px;
    text-align: center;

    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }

  &__description {
    font-family: $font-heading;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.388;
    text-align: center;
    white-space: pre-wrap;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 39px;
    white-space: pre-wrap;
  }

  &__container {
    display: grid;

    @media only screen and (max-width: 600px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  &__content {
    justify-self: center;
    align-items: flex-start;
    width: 100%;
    @media only screen and (max-width: 600px) {
      overflow-x: hidden;
    }
  }

  &__back {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px !important;

    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }

  &__footer {
    margin-top: 56px;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    > * + * {
      margin-top: 24px;
    }

    &--isFloat {
      position: absolute;
      top: -10px;
      right: 16px;
      margin-top: 0;
    }
  }

  &--primary {
    background: $color-secondary;

    .StepLayout__title {
      font-family: $font-heading;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.266;
      text-align: left;
      margin-bottom: 24px;
    }

    .StepLayout__description {
      font-family: $font-body;
      font-size: 16px;
      line-height: 1.25;
      text-align: left;
      max-width: 500px;
    }

    .StepLayout__content {
      margin-top: 128px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .StepLayout__skip {
      display: flex;
      margin-top: 20px;
      span {
        font-family: $font-heading;
        font-size: 14px;
        margin-left: 10px;
      }

      input[type="checkbox"] {
        accent-color: $color-button;
        height: 16px;
        width: 16px;
      }
    }
  }

  &--primary-white {
    .StepLayout__title {
      font-family: $font-heading;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.266;
      text-align: left;
      margin-bottom: 24px;
    }

    .StepLayout__description {
      font-family: $font-body;
      font-size: 16px;
      line-height: 1.25;
      text-align: left;
    }
  }

  &--primary-white {
    .StepLayout__title {
      font-family: $font-heading;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.266;
      text-align: left;
      margin-bottom: 24px;
    }

    .StepLayout__description {
      font-family: $font-body;
      font-size: 16px;
      line-height: 1.25;
      text-align: left;
    }
  }
}
