.custom {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.confirmation-container {
    margin-top: 25px;

    @media only screen and (max-width: 600px) { 
        width: 100vw;
      }
}

.bot-body {
    background-color: #F6F8FB;
    padding: 25px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
}

.bot-message-wrap {
    background-color: #312f2f;
    border-radius: 20px;
    border-bottom-left-radius: 0px;
    word-wrap: break-word;
    font-size: 16px;
    color: white;
    text-align: left;
    padding: 15px 20px;
    font-family: 'Lato', sans-serif;
}

.tile-custom {
    width: 350px;
    margin-top: 20px;

    @media only screen and (max-width: 600px) { 
        width: 94%;
    }
}

.icon-be-wrap {
    height: 100px;
    width: 100px;
    background-color: black;
    border-radius: 50%;
    margin-top: 20px;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 70px;
        width: 70px;
    }

    @media only screen and (max-width: 600px) { 
        height: 80px;
        width: 80px;

        img {
            height: 50px;
            width: 50px;
        }
    
    }
}

.confirmation {
    @media only screen and (max-width: 600px) { 
        width: 100vw;
      }
}
  
  
  
  