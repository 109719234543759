@import '../../scss/settings.colors';

.Progress {
  width: 400px;
  height: 40px;
  background: #EBEBEB;
  border-radius: 4px;
  position: relative;

  &__bar {
    background: $color-dark-gray;
    height: 100%;
    max-width: 100%;
    border-radius: 8px;
    transition: 600ms width ease-in-out;
    transition-property: width, background-color;

    &--color {
      &--green {
        background: $color-green;
      }
  
      &--amber {
        background: $color-amber;
      }
  
      &--red {
        background: $color-red;
      }
      &--work{
        background: #0076BA ;
      }
      &--life{
        background: #CB297B;
      }

    }

  }

}