.ReportProgress {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  height: 65px;

  &--disabled {
    opacity: 0.28;
  }

  &__figure {
    display: flex;
    justify-content: space-between;

    .Progress {
      width: 180px;
    }
  }

  &__content {
    width: 250px;
  }

  @media only screen and (max-width: 600px) {
    .Progress {
      width: 160px;
    }

    &__content {
      width: 100px;
    }
  }

  &__animated {
    .Heading {
      animation: mymove 2s;
    }
  }

  @keyframes mymove {
    50% {
      font-size: 40px;
    }
  }
}

.reportFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  width: 90px;
  align-items: center;
  margin-right: -15px;

  @media only screen and (max-width: 600px) {
    width: 70px;
    margin-left: 20px;
  }
}
