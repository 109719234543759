.CheckInSlider {
  background: #fff;
  padding: 44px 24px;
  height: 410px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) { 
    box-shadow: none;
  }

  &__title {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.25 !important;
  }

  &__slider {
    margin-top: 67px;
    margin-top: auto;
  }
}
