.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;

  background: rgba(0,0,0,0.48);
  z-index: 1000;

  &__content {
    background: #fff;
    padding: 24px;
    
  }
}