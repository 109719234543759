.TextField {
  position: relative;

  &__label {
    color: #aaaaaa;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.071;
    position: absolute;
    top: 10px;
    left: 26px;
    text-transform: unset;
  }

  &__input {
    margin-bottom: 5px;
    &--isError {
      box-shadow: inset 0px 0 0 2px #fd6f66;
    }
  }

  &__error {
    color: red;
    font-size: 14px;
    line-height: 1.071;
    min-height: 15px;
    margin-bottom: 5px;
  }

  &__endAdornment {
    position: absolute;
    top: 10px;
    right: 0;
  }
}
