@import '../../scss/settings';

.CheckinAll {
  &__sliders {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 6px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  //for mobile view
  &__mobileViewContainer {
    overflow-y: hidden;
    overflow-x: hidden;
    height: 88vh;
  }

  &__swiper {
    height: 100%;
    width: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__slideContainer {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    padding: 0 15px;

    @media only screen and (max-width: 600px) { 
      padding: 0;
      width: 100vw;
      align-items: center;
    }

    .Slider {
      margin-top: 60px;
    }

    .Text {
      text-align: center;
      margin-top: 20px;
    }

    .Heading {
      margin-top: 5vh;
    }
  }

  &__navigationWrap {
    flex-direction: column;
    position: absolute;
    right: 10px;
    top: 0px;
    margin-top: 55vh;
    margin-bottom: auto;
    z-index: 9998;
  }

  &__buttonSubmit {
    position: absolute;
    bottom: 20px;
    left: 22.5vw;
    width: 55vw;
    z-index: 9998;
  }

  &__buttonSubmitIdentity {
     width: 100%;
     margin-top: 50px;
  }

  &__navigation {
    height: 45px;
    width: 45px;
    border-bottom: 1px solid white;
    background-color: $color-secondary;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 20px;
      width: 20px;
      object-fit: contain;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(48deg) brightness(102%) contrast(105%);
    }
  }

  &__desktopView {
    display: block;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  &__mobileView {
    display: none;

    @media only screen and (max-width: 600px) {
      display: flex;
      touch-action: none;
    }
  }

  &__mobileViewDisplay {
    display: none;

    @media only screen and (max-width: 600px) {
      display: flex;
    }
  }
}
