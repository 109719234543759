@import '../../scss/settings';

.PhoneNumberField {
  position: relative;

  &__prefixSelect {
    appearance: none;
    border: none;
    background: none;
    position: absolute;
    bottom: 30px;
    left: 27px;
    z-index: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    padding: 0;
    color: $color-primary;
    border-right: solid 1px #dedede;
    padding-right: 8px;
    border-radius: 0;

    select {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;

      &:focus {
        outline: none;
      }
    }
  }

  &__textField {
    .TextField__input {
      padding-left: 86px;
    }
  }
}
