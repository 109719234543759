@import '../../scss/settings.colors';

.Input {
  background: #ffffff;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 30px 26px 11px 26px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  color: $color-primary;
  border: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    width: 100%;
    box-sizing: border-box;   
  }

  &::placeholder {
    color: #aaaaaa;
  }

  &:focus {
    box-shadow: inset 0px 0 0 2px $color-button;
  }

  &:disabled {
    color: #aaa;
  }
}
