@import "../../../scss/settings";

.WellbeingRisk {
  &__chartSection {
    margin-bottom: 48px;
    display: grid;
    gap: 24px;
    align-items: flex-start;

    @include media-breakpoint-up(lg) {
      grid-template-columns: 5fr 7fr;
    }
  }

  &__breakdown {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 24px;
    gap: 48px;
  }

  &__heatmapHeader {
    display: flex;
    align-items: center;
    padding: 20px;
    padding-bottom: 0px;
    border-bottom: 1px solid black;
    font-size: 18px;
  }

  &__noDataText {
    margin-top: 40px;
    padding-left: 8%;
    padding-right: 8%;
    height: 300px;
    line-height: 22px;
  }

  &__mobileToggle {
    flex: 1;
    margin-top: 20px;
    display: flex;
    margin-right: 20px;
    justify-content: flex-end;
  }

  &__driverPercentY {
    margin-top: 90px;
    margin-left: 15px;
  }

  &__dividerPercentY {
    margin-top: 185px;
    margin-left: 15px;
  }

  &__drainerPercentY {
    margin-top: 350px;
    margin-left: 15px;
  }

  &__driverPercentX {
    margin-top: 530px;
    margin-left: 190px;
  }

  &__dividerPercentX {
    margin-top: 530px;
    margin-left: 368px;
  }

  &__drainerPercentX {
    margin-top: 530px;
    margin-left: 450px;
  }

  //top
  &__topLeftPercent {
    margin-top: 90px;
    margin-left: 185px;
  }

  &__topMiddlePercent {
    margin-top: 90px;
    margin-left: 370px;
  }

  &__topRightPercent {
    margin-top: 90px;
    margin-left: 450px;
  }

  //middle
  &__middleLeftPercent {
    margin-top: 185px;
    margin-left: 185px;
  }

  &__middlePercent {
    margin-top: 185px;
    margin-left: 370px;
  }

  &__middleRightPercent {
    margin-top: 185px;
    margin-left: 450px;
  }

  //right
  &__bottomLeftPercent {
    margin-top: 350px;
    margin-left: 185px;
  }

  &__bottomMiddlePercent {
    margin-top: 350px;
    margin-left: 370px;
  }

  &__bottomRightPercent {
    margin-top: 350px;
    margin-left: 450px;
  }

  @media only screen and (max-width: 600px) {
    &__driverPercentY {
      margin-top: 60px;
      margin-left: 4px;
    }

    &__dividerPercentY {
      margin-top: 115px;
      margin-left: 4px;
    }

    &__drainerPercentY {
      margin-top: 220px;
      margin-left: 4px;
    }

    &__driverPercentX {
      margin-top: 330px;
      margin-left: 120px;
    }

    &__dividerPercentX {
      margin-top: 330px;
      margin-left: 230px;
    }

    &__drainerPercentX {
      margin-top: 330px;
      margin-left: 282px;
    }

    //top
    &__topLeftPercent {
      margin-top: 60px;
      margin-left: 120px;
    }

    &__topMiddlePercent {
      margin-top: 60px;
      margin-left: 230px;
    }

    &__topRightPercent {
      margin-top: 60px;
      margin-left: 282px;
    }

    //middle
    &__middleLeftPercent {
      margin-top: 115px;
      margin-left: 120px;
    }

    &__middlePercent {
      margin-top: 115px;
      margin-left: 230px;
    }

    &__middleRightPercent {
      margin-top: 115px;
      margin-left: 282px;
    }

    //right
    &__bottomLeftPercent {
      margin-top: 220px;
      margin-left: 120px;
    }

    &__bottomMiddlePercent {
      margin-top: 220px;
      margin-left: 230px;
    }

    &__bottomRightPercent {
      margin-top: 220px;
      margin-left: 282px;
    }
  }
}

.inlineHeat {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  &__marginRight {
    margin-right: 10px;
  }
}

.HeatmapEmptySection {
  margin-top: 20px;
  height: 300px;
  width: 60%;
}

.HeatmapEmptySectionProfiler {
  margin-top: 20px;
  height: 300px;
  width: 100%;
}
