@import '../../scss/_settings';

.Footer {
  background: #000000;
  color: #646476;
  font-size: 12px;
  line-height: 1.25;
  padding-top: 62px;
  padding-bottom: 62px;
  height: 396px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * + * {
      margin-left: 30px;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: stretch;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin-left: auto;
    }

    a {
      color: white;
      font-family: "Lato", sans-serif;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
