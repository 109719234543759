@import '../../scss/settings';

.Navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    &.active {
      color: $color-button;
    }
  }
}
