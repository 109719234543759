@import "../../scss/settings";

.Header {
  // background: #fff;
  padding-bottom: 0px;
  // display: flex;
  align-items: center;
  padding-left: 0px;
  height: 13vh;

  @media only screen and (max-width: 600px) { 
    height: 11vh
  }

  &__logo {
    margin: auto;
    position: absolute;

    @include media-breakpoint-down(xs) {
      img {
        height: 22px;
      }
    }
  }

  &__checkIn {
    position: absolute;
    top: 25px;
    right: 5%;
  }

  &__dashboard {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: #fff;
    padding-top: 20px;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 20px;
    border-bottom: 1px solid #cecece;
    height: 52px;
  }

  &__menu {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 14px;
    }
  }

  &__nav {
    overflow: hidden;
    max-width: 0;
    transition: max-width 240ms ease-out;
    height: 1000px;
    position: absolute;
    top: 0;
    background-color: black;
    z-index: 9999;

    &--isOpen {
      max-width: 380px;
      padding-bottom: 10px;

      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
    }

    .Button {
      justify-content: flex-start;
      color: white;
      //font-weight: normal;
      font-size: 24px;
      font-family: "Poppins", sans-serif;
      //font-family: "Lato", sans-serif;
      font-weight: 600;
      line-height: 30px;
      width: 100%;
      margin-top: 10px;
    }

    #home {
      margin-top: 30px;
    }

    .IconButton {
      margin-left: 15px;
      margin-top: 20px;
    }
  }

  &__avatar {
    height: 45px !important;
    width: 45px !important;
    font-size: 20px !important;
  }

  &__avatarWrap {
    position: absolute;
    right: 50px;

    @include media-breakpoint-down(xs) {
      right: 20px;
    }
  }

  &__profileNav {
    overflow: hidden;
    max-height: 0;
    transition: max-height 240ms ease-out;
    position: absolute;
    z-index: 9999;

    &--isOpen {
      max-height: 500px;
      padding-bottom: 10px;
    }

    .Button {
      justify-content: flex-start;
      margin-right: -30;
      font-size: 16px;
      font-weight: normal;
      margin-top: -20px;
      color: black;

      &:hover {
        color: #3297f5
      }
    }

    #signOut {
      margin-top: 10px;
    }
  }
  
  &__homeIconWrap {
    height: 43px;
    width: 43px;
    border-radius: 30px;
    border-color: white;
    border-style: solid;
    border-width: 2px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10px;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__menuIcon {
    cursor: pointer;
    position: absolute;
    left: 50px;

    @include media-breakpoint-down(xs) {
      left: 20px;
    }
  }

  &__closeIcon {
    cursor: pointer;
  }

  &__homeIcon {
    height: 45px;
    width: 45px;
  }
}
