.Signin {
  &__title {
    margin-bottom: 56px;
    text-align: center;
  }

  &__remember {
    margin-top: 17px;
  }

  &__submit {
    margin-top: 22px;
  }

  &__footer {
    margin-top: 55px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    flex-direction: column;
    display: flex;
    gap: 30px;
  }

  &__form {
    @media only screen and (max-width: 600px) { 
     padding-left: 25px;
     padding-right: 25px;
    }
  }
}
