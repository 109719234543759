@import '../../scss/settings.colors';

.Page {
  padding-top: 41px;
  padding-bottom: 41px;

  @media only screen and (max-width: 600px) { 
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
  }

  &__backLink {
    display: inline-block;
    color: $color-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    margin-bottom: 30px;
    width: auto;
    padding: 10;
    display: flex;
    gap: 10px;
  }

  &__content {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
