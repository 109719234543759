@import '../../scss/settings';

.Heading {
  font-family: $font-heading;
  font-weight: 600;
  color: $color-primary;
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 0;

  &--variant {
    &--md {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.388;
    }

    &--lg {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.388;
    }

    &--xl {
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
    }

    &--xxl {
      font-size: 30px;
      font-weight: 600;
      line-height: 1.266;
    }

    &--headlineXL {
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
    }

    &--valueXL {
      font-size: 40px;
      line-height: 1.2;
      font-weight: 600;
    }

    &--valueL {
      font-size: 30px;
      line-height: 1.2;
      font-weight: 600;
    }
  }

  &--gutterBottom {
    margin-bottom: 22px;
  }
}
