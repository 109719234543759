@import '../../scss/settings.colors';

.FormField {
  position: relative;

  &__label {
    color: #aaaaaa;
    font-size: 14px;
    line-height: 1.071;
    position: absolute;
    top: 10px;
    left: 26px;
  }

  &__control {
    background: #ffffff;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    color: $color-primary;
    border: none;
    width: 100%;
    margin-bottom: 5px;
    padding: 30px 16px 11px 27px;

    &--isError {
      box-shadow: inset 0px 0 0 2px #fd6f66;
    }
  }

  &__error {
    color: #aaaaaa;
    font-size: 14px;
    line-height: 1.071;
    min-height: 15px;
    margin-bottom: 5px;
  }
}
