@import '../../scss/settings';

.Text {
  white-space: pre-line;
  font-family: $font-body;
  font-weight: 400;
  line-height: 1.25;

  &--variant {
    &--xs {
      font-size: 12px;
      line-height: 1.25;
    }

    &--sm {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.071;
    }

    &--md {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.25;

      &--bold {
        font-family: $font-heading;        
        font-weight: 600;
      }
    }
  }
}
