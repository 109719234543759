@import '../../scss/settings.colors';

.Form {
  display: flex;
  flex-direction: column;

  legend {
    font-size: 16px;
    font-weight: 700;
    color: $color-primary;
    text-transform: uppercase;
    margin-bottom: 21px;
  }

  fieldset + fieldset {
    margin-top: 40px;
  }
}
