@import '../../scss/settings';

.Layout {
  font-family: $font-body;
  font-size: 16px;
  color: $color-primary;
  min-height: 100vh;
  background-color: white;

  display: flex;
  flex-direction: column;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  &__main {
    margin-top: 80px
  }
}
