.IconButton {
  background: none;
  min-width: auto;
  padding: 0;

  &--size {
    &--md {
      width: 46px;
      height: 46px;
    }
  }

  &--variant {
    &--circle {
      border-radius: 100%;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);

      &:disabled {
        background: none;
        opacity: 0.8;
      }
    }
  }
}
