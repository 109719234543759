@import '../../scss/settings';

.Container {
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &--lg {
    width: #{1200 + 24px * 2};

    @media only screen and (max-width: 600px) { 
      width: 100vw;
      overflow-y: hidden;
    }
  }

  &--xlg {
    width: #{1300 + 24px * 2};

    @media only screen and (max-width: 600px) { 
      width: 100vw;
      overflow-y: hidden;
    }
  }

  &--max {
    width: 90vw;
    padding-left: 5%;
    padding-right: 5%;
    @media only screen and (max-width: 600px) { 
      width: 100vw;
      overflow-y: hidden;
    }
  }

  &--md {
    width: #{1024 + 24px * 2};

    @media only screen and (max-width: 600px) { 
      width: 100vw;
      overflow-y: hidden;
    }
  }

  &--msm {
    max-width: #{634 + 24px * 2};

    @media only screen and (max-width: 600px) { 
      width: 100vw;
      overflow-y: hidden;
    }
  }

  &--sm {
    max-width: #{334 + 24px * 2};

    @media only screen and (max-width: 600px) { 
      width: 100vw;
      overflow-y: hidden;
    }
  }

  &--fluid {
    max-width: none!important;
    padding-left: 0!important;
    padding-right: 0!important;
  }
}
