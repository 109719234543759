/*#container {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*!*  flex-basis: auto;*!*/
/*  flex: auto;*/
/*!*  flex-grow: 1;*!*/
/*!*  justify-content: flex-grow;*!*/
/*  margin-top: 20px;*/
/*  margin-right: 8px;*/
/*}*/

.box1 {
  border-radius: 25px;
  padding: 20px;
  background-color: #fff;
  color: #000;
  opacity: .8;
  flex: auto;
  flex-direction: column;
  /*  flex-wrap: wrap;*/
  justify-content: space-around;
}

.resource-container {
  /*align-self: center;*/
  display: flex;
  /*margin-left: 100px;*/
  flex: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  font-family: "Lato", sans-serif;
  margin-left: 10%;
  margin-right: 10%;
}

/*#container-header {*/
/*  background-color: #000000;*/
/*  color: #FFFFFF;*/
/*  border-radius: 25px;*/
/*  display: flex;*/
/*!*  flex-basis: auto;*!*/
/*  flex: auto;*/
/*  flex-direction: column;*/
/*!*  flex-wrap: wrap;*!*/
/*  justify-content: space-around;*/
/*}*/

/*#container-bot {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  flex-wrap: wrap;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  align-content: center;*/
/*  margin-left: 10px;*/
/*  margin-right: 10px;*/
/*  margin-top: 50px;*/
/*}*/



/*.media-viewer {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  align-content: center;*/
/*}*/


/*h1 {*/
/*  !*font-family: "Caveat", cursive;*!*/
/*  font-family: "Lato", monospace;*/
/*  font-size: 2rem;*/
/*  color: #000000;*/
/*  margin: 0;*/
/*}*/

h3 {
  font-family: "Lato", monospace;
  font-size: 1rem;
  color: #FFFFFF;
}

/*.subtitle {*/
/*  font-size: 1.5rem;*/
/*  color: #000000;*/
/*  text-transform: uppercase;*/
/*}*/

.subtitle-lato {
  font-family: "Lato", monospace;
  text-align: center;
  font-size: 1.5rem;
  color: #000000;
  text-transform: uppercase;
}

/*nav a {*/
/*  font-size: 1.5rem;*/
/*  color: #FFFFFF;*/
/*  text-transform: uppercase;*/
/*}*/

.bot-logo img {
  margin-left: 2em;
  width: 200px;
  vertical-align: middle;
}


.chatbot {
  font-family: "Lato", sans-serif;
  border-radius: 10px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background: #f5f8fb;
  text-align: center;
  display: flex;
  flex-flow: column;
  width: 500px;
  overflow: hidden;
  position: fixed;
  bottom: 100px;
  right: 10px;
  z-index: 9999;

  @media only screen and (max-width: 600px) { 
    width: 95vw;
    bottom: 100px;
  }
}

.bot-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8em 0;
  background-color: #000000;
  box-shadow: 2px 2px 10px #f1f6f9;
}

.bot-header-icon{
  margin-left: 20px;
}

.bot-header-text {
  padding-top: 20px;
  padding-left: 20px;
  text-align: center;
  color: #000000;
  max-height: 58px;
  font-size: 20px;
  font-weight: bold;
}

.bot-header-text-invert {
  padding-top: 25px;
  color: #FFFFFF;
  max-height: 58px;
  font-size: 15px;
  font-weight: bold;
  padding-right: 20px;
}

.messages {
  width: 100%;
  height: 470px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  @media only screen and (max-width: 600px) { 
    height: 55vh;
  }
}

 .message-container-user {
  width: 100%;
}


.message-container-bot {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  width: 100%;
}

.bot-article {
  white-space:pre-wrap;
  float: left;
  padding: 15px 20px;
}

.bot-breather {
  white-space:pre-wrap;
  float: left;
  padding: 15px 20px;
}

.bot-message {
  /*white-space:pre-wrap;*/
  white-space:normal;
  float: left;
  padding: 15px 20px;
  margin: 2px 5px;
  border-radius: 20px 20px 20px 1px;
  background: #312f2f;
  color: white;
  min-width: 40px;
  max-width: 300px;
  text-align: left;
  word-wrap: break-word;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  /*white-space:pre-wrap;*/
  /*text-wrap: wrap;*/

  @media only screen and (max-width: 600px) { 
    font-size: 17px;
  }
}

.bot-message > p{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.bot-message a:link {
  color: #f36011;
}

.bot-message a:visited {
  color: #69f8de;
}

.bot-message a:link:active, a:visited:active {
  color: #FF0000;
}

.bot-tiles {
  /* margin-top: 10px; */
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 16px;
  justify-content: flex-start;
  font-family: 'Lato', sans-serif;

  @media only screen and (max-width: 600px) { 
    font-size: 17px;
  }
}

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
  color: #333;
  cursor: pointer;
  min-width: 50px;
  text-align: left;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add box shadow for the elevated effect */
  transition: background-color 0.3s ease; /* Add a smooth transition for background color */
  white-space:pre-wrap;
  margin-top: 5px;

}

.tile:hover {
  background: #ddd; /* Change the background color on hover */
}
.user-message {
  text-align: left;
  float: right;
  padding: 15px 10px;
  margin: 10px;
  border-radius: 20px 20px 1px 20px;
  background: #cccccc;
  color: black;
  max-width: 250px;
  word-wrap: break-word;
  text-wrap: wrap;
  white-space:pre-wrap;

  font-size: 16px;
  font-family: 'Lato', sans-serif;

  @media only screen and (max-width: 600px) { 
    font-size: 17px;
  }
}

.input {
  position: relative;
}

.input > form > input[type="text"] {
  font-family: monospace;
  font-size: 16px;
  border: 0;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #eee;
  box-shadow: none;
  box-sizing: border-box;
  opacity: 1;
  outline: none;
  padding: 16px 52px 16px 10px;
  width: 100%;
  -webkit-appearance: none;
}

.input > form > input:focus {
  outline: none;
}

.chatbot-icon-div{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 15px; 
  right: 25px;
  height: 65px;
  width: 65px;
  border-radius: 35px;
  background-color: #000000;

  @media only screen and (max-width: 600px) { 
    height: 55px;
    width: 55px;
    right: 15px;
    bottom: 20px;
  }
}
.chatbot-icon{
  border-radius: 25px;

  @media only screen and (max-width: 600px) { 
    height: 40px;
    width: 40px;
  }
}

.input > form > button {
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
  padding: 14px 16px 12px 16px;
  position: absolute;
  right: 0;
  top: 0;
}

.bot-tiles-slider {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.bot-slider-limits{
  font-size: 20px;
  font-weight: bold;
  color: #3a3737;
}

.bot-tiles-slider input[type="range"] {
  width: 60%;
  margin: 10px 0;
}


