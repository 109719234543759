.Select {
  appearance: none;
  background: url('../../images/arrow-down.svg') #fff no-repeat right top;
  border-width: 0 0 1px 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 40px;

  &:focus {
    outline: none;
  }
}
