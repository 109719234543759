.ResetPassword {
  &__title {
    font-size: 25px;
    line-height: 1.2;
    margin-bottom: 56px;
    text-align: center;
  }

  &__submit {
    margin-top: 65px;
  }

  &__footer {
    text-align: center;
    margin-top: 55px;
  }

  &__successMessage {
    margin-top: -15px;
    margin-bottom: -16px;
    color: #AAAAAA;
    padding: 0 8px;
  }

  &__form {
    @media only screen and (max-width: 600px) { 
     padding-left: 25px;
     padding-right: 25px;
    }
  }
}
