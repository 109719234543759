@import '../../scss/settings';

.Slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;

  &__label {
    font-family: $font-body!important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 1.142 !important;
    letter-spacing: 0.1em !important;
    color: #aaaaaa !important;
    text-transform: uppercase !important;
    padding: 0 !important;
    height: auto !important;
  }

  .MuiSlider-root {
    position: relative;
    height: 220px;
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .MuiSlider-valueLabel {
    background-color: #00DE91;
    font-weight: bold;
    font-size: 14px;
  }

  &--isDirty {
    .slider {
      .thumb {
        background: $color-secondary;
      }
    }
  }
}
