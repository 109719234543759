@import '../../scss/settings';

$size: 105px;

.Avatar {
  width: $size;
  height: $size;
  border-radius: $size;
  background-color: #8b56db;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 46px;
  font-weight: 900;

  @media only screen and (max-width: 600px) { 
    width: 80px;
    height: 80px;
    font-size: 42px;
  }
}
