@import '../../scss/settings';

.Account {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 40px;

    @media only screen and (max-width: 600px) { 
      padding-left: 20px;
    }
  }

  &__avatar {
    margin-bottom: 17px;
  }

  &__link {
    font-size: 15px;
    line-height: 1.333;
    color: #000;
    background: url('../../images/arrow-next--blue.svg') no-repeat center right;
    padding-right: 24px;
  }

  &__displayName {
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  &__form {
    width: 325px;

    @media only screen and (max-width: 600px) { 
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
      
    }
  }

  &__banner {
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      margin-right: -172px;
    }
  }

  &__submit {
    margin-top: 46px;
    margin-bottom: 21px;
  }
}
