@import "../../../scss/settings";

.Profiler {
  margin-top: 50px;
  
  &__heatmapWrap {
    width: 50%;
    padding-bottom: 50px;
    background-color: white;
    border-radius: 20px;
    border: 1px solid #9f9f9f;
  }

  &__topSection {
    flex: 1;
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }

  &__resultWrap {
    width: 46.5%;
    padding-bottom: 50px;
    background-color: white;
    border-radius: 20px;
    margin-left: 3%;
    padding-right: 20px;
    border: 1px solid #9f9f9f;
  }

  &__diamondChartWrap {
    width: 50%;
    background-color: white;
    border-radius: 20px;
    border: 1px solid #9f9f9f;
  }

  &__diamondContentWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;

    .Heading {
      width: 50%;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  &__factorsWrap {
    width: 50%;
    background-color: white;
    border-radius: 20px;
    border: 1px solid #9f9f9f;
  }

  &__listProgresses {
    > * + * {
      margin-top: 30px;
    }
    margin-top: 40px;
  }

  &__noteWrap {
    display: flex;
    align-items: center;
    p {
      width: 300px;
    }

    .Heading {
      margin-left: 30px;
      margin-top: -15px;
      margin-right: 30px;
    }
  }

  &__resultContentWrap {
    padding-top: 20px;
    padding-left: 20px;
  }

  &__resultTitle {
    font-size: 20px;
    font-weight: 600;
  }

  &__resultValue {
    font-size: 16px;
    line-height: 24px;

    a {
      color: $color-button;
      display: flex;
    }
  }

  &__resultCopyIcon {
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin-left: 10px;
    margin-top: -3px;
  }

  @media only screen and (max-width: 600px) {
    gap: 0;
    padding-bottom: 100px;

    &__topSection {
      display: block;
      gap: 0;
      width: 100%;
    }

    &__diamondChartWrap {
      width: 100%;
      margin-top: 20px;
      margin-left: 0;
      border-radius: 0px;
      padding-bottom: 50px;

      .Heading {
        width: 70%;
      }
    }

    &__heatmapWrap {
      width: 100%;
      margin-left: 0;
      border-radius: 0px;
    }

    &__resultWrap {
      width: 100%;
      margin-left: 0;
      border-radius: 0px;
    }

    &__factorsWrap {
      width: 100%;
      margin-right: 0;
      border-radius: 0px;
      margin-top: 20px;
    }
  }

  &__animated {
    .Heading {
      animation: mymove 2s;
    }
  }

  @keyframes mymove {
    50% {
      font-size: 40px;
    }
  }
}
