@import '../../scss/settings';

.Link {
  color: $color-button;

  &:hover {
    color: $color-button;
    text-decoration: underline;
  }
}
