.CheckinResults {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 113px;

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    li {
      margin-top: 20px;
    }

    ul {
      margin-top: -70px;
    }
    
  }

  &__content {
    max-width: 324px;
    margin-top: 38px;

    @media only screen and (max-width: 600px) { 
      text-align: center;
      margin-bottom: 40px;
      margin-top: 0px;
    }
  }

  &__interDescription {
    margin-top: 10px;
    margin-left: 2px;
    line-height: 24px;
  }
  
  &__interTitle { 
    font-weight: 600 !important;
    margin-top: 20px;
  }

  &__inputContainer {
    margin-top: 30px;
    width: 330px;
    @media only screen and (max-width: 600px) { 
     width: 80%;
    }
  }

  &__identityContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
