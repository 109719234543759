
  
  .ReactTags__tags {
    position: relative;
  }
  
  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
  }
  
  .ReactTags__tagInput {
    border-radius: 2px;
  
    input.ReactTags__tagInputField {
      height: 55px;
      margin: 0;
      font-size: 14px;
      width: 100%;
      box-sizing: border-box;
      border: none;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 4px;
      box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
  
    //   &:focus {
    //     height: 32px;
    //     margin: 0;
    //     padding: 5px;
    //     font-size: 14px;
    //     border: 1px solid #eee;
    //     min-width: 180px;
    //   }
    }
  }
  
  .ReactTags__editInput {
    border-radius: 1px;
  }
  
  .ReactTags__editTagInput {
    display: inline-flex;
  }
  
  .ReactTags__remove {
    display: flex;
  }
  
  .ReactTags__selected {
    display: flex;
    flex-wrap: wrap;
  
    span.ReactTags__tag {
      border: 1px solid #ddd;
      background: #3297f5;
      color: #fff;
      font-size: 14px;
    padding-left: 8px;
      margin-right: 7px;
      margin-bottom: 7px;
      font-weight: 600;
      border-radius: 4px;
      display: flex;
      align-items: center;
    }
  }
  
  .ReactTags__suggestions {
    position: absolute;

    ul {
      list-style-type: none;
      box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
      background: white;
      width: 250px;
      padding: 0;
    
    }
  
    li {
      padding: 12px;
      margin: 0;

      text-align: left;
      font-size: 14px;
      font-weight: 600;
  
      mark {
        text-decoration: none;
        background: none;
      }
  
      &.ReactTags__activeSuggestion {
        background: #3297f5;
        color: #fff;
        cursor: pointer;
  
        span mark {
          color: #fff
        }
      }
    }
  }
  
  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: #fff;
    padding: 10px;
    display: flex
  }
  
  .ReactTags__error {
    color: #e03131;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  
    svg {
      padding: 0 5px;
    }
  }