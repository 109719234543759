.subtitle-lato {
    font-family: "Lato", sans-serif;
    text-align: center;
    font-size: 1.5rem;
    color: #000000;
    text-transform: uppercase;
}

.resource-container {
    /*align-self: center;*/
    display: flex;
    /*margin-left: 100px;*/
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-family: "Lato", sans-serif;
    margin-left: 10%;
    margin-right: 10%;
  }

  .media-viewer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    iframe {
      @media only screen and (max-width: 600px) { 
        width: 95vw;
      }
    }
  }

  .subtitle {
    font-size: 1.5rem;
    color: #000000;
    text-transform: uppercase;
  }
  
  
  
  