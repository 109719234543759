@import '../../scss/settings';

$color-gray: #aaaaaa;

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  height: 60px;
  font-family: $font-heading;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 100%;
  transition: 200ms ease-out;
  transition-property: color, background-color;
  text-decoration: none;

  &:disabled {
    cursor: not-allowed;
  }

  &--isActive {
    color: #3297f5 !important;
  }

  &--size {
    &--xs {
      width: 83px;
      height: 22px;
      font-size: 14px;
      line-height: 1.071;
      font-weight: 400;
    }

    &--sm {
      min-width: 120px;
      height: 36px;
      font-size: 14px;
      line-height: 1.25;
      font-weight: 400;
    }

    &--md {
      width: 200px;
      height: 46px;
    }

    &--lg {
      width: 382px;
      height: 60px;
    }
  }

  &--isFull {
    width: 100%;
  }

  &--variant {
    &--primary {
      background: $color-button;
      color: #fff;

      &:hover {
        background: mix(black, $color-button, 10%);
        color: #fff;
      }

      &:disabled {
        background: #aaaaaa;
      }
    }

    &--secondary {
      background: #fff;
      color: $color-primary;

      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }
    }

    &--ghost {
      background: none;

      &:hover {
        color: $color-button;
      }

      &:disabled {
        color: #6f6d76;
      }

      &-blue {
        color: $color-button;
        background: none;
      }

      &.Button--palette--gray {
        color: $color-gray;
      }
    }

    &--link {
      color: $color-button;
      padding: 0;
      background: none;
      width: auto;
    }

    &--outline {
      border: solid 2px #fff;
      background: none;

      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }
    }

    &--outline-blue {
      border: solid 2px $color-button;
      background: none;

      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }
    }

    &--outline-xs {
      border: solid 1px #2a233e;
      background: none;

      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }
    }

    &--black {
      background: $color-primary;
      color: #fff;

      &:disabled {
        background: #aaaaaa;
      }

      &.Button--palette--gray {
        background: #aaaaaa;
      }
    }
  }
}
