@import '../../scss/settings';

.Signup {
  margin-bottom: 41px;

  &__title {
    margin-bottom: 56px;
    text-align: center;
  }

  &__form {
    @include media-breakpoint-up(lg) {
      display: grid!important;
      grid-template-columns: 1fr 1fr;
      gap: 216px;
    }

    @media only screen and (max-width: 600px) { 
      padding-left: 25px;
      padding-right: 25px;
     }
  }

  &__submit {
    margin-top: 73px;
    @media only screen and (max-width: 600px) { 
      margin-top: 10px;
    }
  }

  &__footer {
    margin-top: 16px;
  }
}
